import moment from 'moment';
import { Platform } from 'react-native';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { createPalmReport, getPalm, getPalmReports as requestPalmReports } from 'api/palm-reading';
import { LONG_PATTERN } from 'constants/moment';
import { PATH_TYPE, STEPS, WHOSE_HAND_TYPES } from 'constants/palm-reading-daily';
import { AppDispatch, AppGetState } from 'store';
import { getDocumentDirectory } from 'utils/cache-manager';
import { downloadImage } from 'utils/process-image';

import { HANDS, PalmMetadata, TYPES } from './types';

const setReports = createAction(TYPES.SET_REPORTS);
const setReport = createAction(TYPES.SET_REPORT);
const setReadyTimer = createAction(TYPES.SET_READY_TIMER);
const setRescanTimer = createAction(TYPES.SET_RESCAN_TIMER);
const setMigratedUserId = createAction(TYPES.SET_MIGRATED_USER_ID);
export const setPending = createAction(TYPES.SET_PENDING);
const setReportPreview = createAction(TYPES.SET_REPORT_PREVIEW);

export const migrate = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { id },
      palmReading: { reports, previews, migratedUserId },
      palmReadingDaily,
    } = getState();

    if (id === migratedUserId) {
      return false;
    }

    const leftHandImage = palmReadingDaily.left?.image;
    const rightHandImage = palmReadingDaily.right?.image;

    try {
      if (!reports?.[HANDS.LEFT]?.created_at && leftHandImage?.uri) {
        const uri = Platform.OS === 'web' ? leftHandImage.uri : getDocumentDirectory() + leftHandImage?.uri?.split('/')?.pop();
        await dispatch(createReport(uri, {}, HANDS.LEFT, PATH_TYPE.MIGRATION));
        Analytics.track('PRD_Migration_Success', { hand: HANDS.LEFT });
      }

      if (!reports?.[HANDS.RIGHT]?.created_at && rightHandImage?.uri) {
        const uri = Platform.OS === 'web' ? rightHandImage.uri : getDocumentDirectory() + rightHandImage?.uri?.split('/')?.pop();
        await dispatch(createReport(uri, {}, HANDS.RIGHT, PATH_TYPE.MIGRATION));
        Analytics.track('PRD_Migration_Success', { hand: HANDS.RIGHT });
      }

      // create report with quiz photo
      if (!leftHandImage?.uri && !reports?.[HANDS.LEFT]?.created_at && !previews?.[HANDS.LEFT]?.uri) {
        const result = await getPalm();
        if (result?.palm_reading?.left_hand_path) {
          await dispatch(createReport(result.palm_reading.left_hand_path, {}, HANDS.LEFT, PATH_TYPE.MIGRATION));
          Analytics.track('PRD_Migration_Success', { hand: HANDS.LEFT });
        }
      }
    } catch (e) {
      Analytics.track('PRD_Migration_Error', { error: String(e) });
      console.log('> Migrate to new palm report error', e);
    }

    dispatch(setMigratedUserId(id));
  };
};

export const createReport = (uri: string, metadata: PalmMetadata, hand: HANDS, pathType: PATH_TYPE, whoseHand?: WHOSE_HAND_TYPES) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { rescan } = getState().remoteConfig.remoteConfigParams;
    const isRescan = !!whoseHand;

    const preview = { uri, date: '' };

    try {
      // Download palm photo from quiz on migration
      if (uri?.startsWith('http') && pathType === PATH_TYPE.MIGRATION) {
        try {
          const res = await fetch(uri, { method: 'HEAD' });
          if (res.ok) {
            const step = hand === HANDS.LEFT ? STEPS.LEFT_HAND : STEPS.RIGHT_HAND;
            const image = await downloadImage(uri, step);
            preview.uri = image?.uri ? image.uri : uri;
          }
        } catch (e) {
          console.log('> Download quiz palm error', e);
        }
      }

      dispatch(setReportPreview({ [hand]: preview }));

      const report = await createPalmReport(uri, metadata, hand);
      preview.date = report.created_at;
      dispatch(setReport({ [hand]: report }));
      dispatch(setReportPreview({ [hand]: preview }));
      dispatch(setPending({ [hand]: false }));

      Analytics.track('PRD_Create_Report_Success', { path_type: pathType, hand, is_rescan: isRescan });

      if (rescan?.enabled && isRescan) {
        const timer = moment().add(rescan.timeout, 'days').format(LONG_PATTERN);
        dispatch(setRescanTimer({ [hand]: timer }));
      }
    } catch (e) {
      dispatch(setPending({ [hand]: false }));
      console.log('> Create report error', e);
      Analytics.track('PRD_Create_Report_Error', { error: (e as Error).message, path_type: pathType, hand, is_rescan: isRescan });
    }
  };
};

export const setUpReadyTimer = (hand: HANDS) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const minutes = getState().remoteConfig.remoteConfigParams.prdReportsReadyDelay;
    if (minutes && minutes > 0) {
      dispatch(setReadyTimer({ [hand]: moment().add(minutes, 'minutes').valueOf() }));
    }
  };
};

export const getPalmReports = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { previews } = getState().palmReading;
    const { remoteConfigParams } = getState().remoteConfig;

    if (!remoteConfigParams?.palmReadingLandingEnabled) {
      return false;
    }

    try {
      const reports = await requestPalmReports();
      dispatch(setReports(reports));

      const downloadPreview = async (hand: HANDS) => {
        const step = hand === HANDS.LEFT ? STEPS.LEFT_HAND : STEPS.RIGHT_HAND;
        if (reports?.[hand]?.created_at && reports?.[hand]?.created_at !== previews?.[hand]?.date) {
          try {
            const image = await downloadImage(reports?.[hand]?.path, step);
            const preview = { uri: image.uri, date: reports?.[hand]?.created_at };
            dispatch(setReportPreview({ [hand]: preview }));
            Analytics.track('PRD_Report_Photo_Downloaded', { hand });
          } catch (e) {
            Analytics.track('PRD_Report_Photo_Download_Error', { hand, error: String(e) });
            console.log(`> Download ${hand} palm report photo error`, e);
          }
        }
      };

      await Promise.all([downloadPreview(HANDS.LEFT), downloadPreview(HANDS.RIGHT)]);

      // migration from quiz amd old app versions.
      dispatch(migrate());
    } catch (e) {
      console.log('> Get reports error', e);
    }
  };
};
